import { TypeObject } from '../typings';
import getUrlParams from '../helpers/get-url-params';

export default function urlParametersEvents(): void {
    const pagePath: string = window.location.pathname;
    const params: TypeObject = getUrlParams();

    Object.keys(params).forEach(function (key: string) {
        const value: string = params[key];

        Analytics.sendEvent({
            category: 'URL Params',
            action: `${key} - ${pagePath}`,
            label: value,
            non_interactive_event: true,
        });
    });
}
