enum COLOR_SCHEME {
    LIGHT = 'light',
    DARK = 'dark',
    NO_PREFERENCE = 'no-preference',
    NOT_SUPPORTED = 'not supported',
}

export default function detectPreferedColorScheme(): void {
    let colorScheme: string;

    if (window.matchMedia) {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            colorScheme = COLOR_SCHEME.DARK;
        } else if (window.matchMedia('(prefers-color-scheme: light)').matches) {
            colorScheme = COLOR_SCHEME.LIGHT;
        } else {
            colorScheme = COLOR_SCHEME.NO_PREFERENCE;
        }
    } else {
        colorScheme = COLOR_SCHEME.NOT_SUPPORTED;
    }

    Analytics.set('prefers_color_scheme', colorScheme);
}
