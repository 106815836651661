import { TypeObject } from '../typings';

const keysMap: TypeObject = {
    version: 'v',
    property_id: 'tid',
    client_id: 'cid',
    user_id: 'uid',
    type: 't',
    timestamp: 'z',
    event_order: '_s',
    country: 'geoid',
    currency: 'cu',
    data_source: 'ds',
    // dimensions
    account_modifier: 'cd1',
    account_type: 'cd2',
    interface: 'cd3',
    account_group: 'cd4',
    orientation: 'cd5',
    fullscreen: 'cd6',
    adblock_status: 'cd7',
    referrer: 'cd8',
    account_id: 'cd9',
    video_session_id: 'cd10',
    do_not_track: 'cd11',
    pixel_ratio: 'cd12',
    session_id: 'cd13',
    login_id: 'cd14',
    frontend_version: 'cd15',
    prefers_color_scheme: 'cd16',
    // visitor data
    document_location: 'dl',
    document_path: 'dp',
    document_title: 'dt',
    document_encoding: 'de',
    document_referrer: 'dr',
    screen_resolution: 'sr',
    java_enabled: 'je',
    viewport_size: 'vp',
    color_depth: 'sd',
    user_language: 'ul',
    user_agent: 'ua',
    // events
    event_category: 'ec',
    event_label: 'el',
    event_action: 'ea',
    value: 'ev',
    non_interactive: 'ni',
    // timing
    user_timing_variable: 'utv',
    user_timing_time: 'utt',
    user_timing_label: 'utl',
};

export default function renameObjectKeys(object: TypeObject): Object {
    return Object.keys(object).reduce((acc: Object, key: string) => {
        return {
            ...acc,
            ...{ [keysMap[key] || key]: object[key] },
        };
    }, {});
}
