export default function deleteFromExcludedList(requestUrl: string): string {
    const EXCLUDE_LIST: string[] = ['sidk']; // 'videoSessionGUID', 'videoSessionID'

    const url: URL = new URL(requestUrl);
    const pathnameParams: string[] = url.pathname.split('&');
    const pathname: string = pathnameParams[0];

    let query: string = url.search.slice(1);
    query = query.replace('?', '&');

    if (pathnameParams.length > 1 && query) {
        query = [pathnameParams.slice(1).join('&'), url.search.slice(1)].join('&');
    } else if (pathnameParams.length > 1) {
        query = pathnameParams.slice(1).join('&');
    }

    const urlParams: URLSearchParams = new URLSearchParams(query);

    EXCLUDE_LIST.forEach((item: string) => {
        urlParams.delete(item);
    });

    let params: string = urlParams.toString();
    params = params ? `?${params}` : '';

    return `${url.origin}${pathname}${params}${url.hash}`;
}
