export default function inputEvents(): void {
    document.addEventListener('change', (e: Event) => {
        const target = e.target;

        if ((target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement || target instanceof HTMLSelectElement) && target.type !== 'password') {
            const form: HTMLFormElement = target.closest('form') as HTMLFormElement;

            Analytics.sendEvent({
                category: 'Input Fields',
                action: form ? form.id : 'No form (standalone)',
                label: target.id ? `${target.id} change` : `${target.name} change`,
            });
        }
    });
}
