export default function buttonEvents(): void {
    const buttonTypes: string[] = ['button', 'reset', 'submit', 'button', 'image'];

    document.addEventListener('click', (e: Event) => {
        const target = e.target;

        if (target instanceof HTMLButtonElement || target instanceof HTMLInputElement) {
            const elmType: string | null = target.getAttribute('type');
            const elmLocalName: string | null = target.localName;

            if ((elmType && buttonTypes.includes(elmType)) || (elmLocalName && buttonTypes.includes(elmLocalName))) {
                const form = target.closest('form') as HTMLFormElement;

                Analytics.sendEvent({
                    category: 'Buttons',
                    action: form ? form.id : 'No form (standalone)',
                    label: target.value,
                });
            }
        }
    });
}
