import getCookie from './get-cookie';

export default function getClientId(): Promise<string> {
    return new Promise((resolve: any) => {
        let gaCid: string;

        if (getCookie('_ga')) {
            gaCid = getCookie('_ga').toString();
            gaCid = gaCid.slice(6);

            resolve(gaCid);
        } else if (typeof ga !== 'undefined' && typeof ga === 'function') {
            ga(() => {
                gaCid = ga.getAll()[0].get('clientId');

                resolve(gaCid);
            });
        } else {
            resolve(null);
        }
    });
}
