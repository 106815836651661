import VisitorData from '../helpers/visitor-data';

export default function screenOrientationChangeEvents(): void {
    window.addEventListener('orientationchange', () => {
        const orientation = VisitorData.getDeviceOrientation();

        if (orientation.type && orientation.angle) {
            Analytics.sendEvent({
                category: 'Orientation',
                action: 'Change',
                label: orientation.type,
                value: Number(orientation.angle),
            });
        }
    });
}
