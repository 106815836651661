import getClientId from './get-client-id';
import { setCookie, setGaCookie } from './set-cookie';
import generateClientId from './generate-client-id';
import Debug from './debug';

export default function setGoogleAnalyticsClientId(): void {
    getClientId()
        .then((result: string) => {
            if (result !== null) {
                setCookie('gacid', result, 30);
            } else {
                const gaCid: string = generateClientId();

                setCookie('gacid', gaCid, 30);
                setGaCookie(gaCid);
            }
        })
        .catch((err: string) => {
            Debug.error(true, err);
        });
}
