export default function quickSearchEvents(): void {
    const quickSearch: HTMLFormElement = document.querySelector('#extraf > form') as HTMLFormElement;

    if (quickSearch) {
        quickSearch.addEventListener('submit', () => {
            const quickSearchValue: HTMLInputElement = quickSearch.querySelector('#cn') as HTMLInputElement;

            return Analytics.sendEvent({
                category: 'Quick Search',
                action: 'Search',
                label: quickSearchValue.value,
            });
        });
    }
}
