import buildFormData from './build-form-data';
import renameObjectKeys from './rename-object-keys';
import { TypeObject } from '../typings';

export default function APIRequest(data: TypeObject): boolean {
    const dataToSend: Object = JSON.stringify(renameObjectKeys(data));
    const url: RequestInfo = '/ns/ga.html';
    const beacon: boolean = navigator.sendBeacon(url, buildFormData({ d: dataToSend }));

    if (!beacon) {
        return beacon;
    }

    return beacon;
}
