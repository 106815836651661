import './polyfills';
import Analytics from './analytics';

const globalAny: any = global;

globalAny.dataLayer = (<any>window).dataLayer || [];
globalAny.gtag = function () {
    (<any>window).dataLayer.push(arguments);
};

export default Analytics;
