import { TypeObject } from '../typings';

import deleteFromExcludedList from './delete-from-excluded-list';

export default class VisitorData {
    constructor() {}

    static getFullscreenStatus(): string {
        const fullscreenEnabled: boolean = document.fullscreenEnabled || document.mozFullScreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled;
        return fullscreenEnabled ? 'Yes' : 'No';
    }

    static getDeviceOrientation(): { type: string; angle: number | string } {
        const orientations: TypeObject = {
            '-90': 'landscape-secondary',
            0: 'portrait-primary',
            90: 'landscape-primary',
            180: 'portrait-secondary',
        };
        const orientation: ScreenOrientation = screen.msOrientation || screen.mozOrientation || screen.orientation;
        // eslint-disable-next-line deprecation/deprecation
        const orientationAngle: number | string = orientation ? orientation.angle : window.orientation;
        const orientationType: string = orientation ? orientation.type : orientations[orientationAngle];

        return { type: orientationType, angle: orientationAngle };
    }

    static getBrowser(): string {
        // eslint-disable-next-line deprecation/deprecation
        let browser: string = window.browser ? window.browser : navigator.appName + '/' + navigator.appVersion;

        if (typeof browser === 'object') {
            browser = JSON.stringify(browser);
        }

        return encodeURIComponent(browser);
    }

    static getBrowserLanguage(): string {
        const language: string = navigator.language;

        return language.toLocaleLowerCase();
    }

    static getBrowserDNT(): string {
        if (window.doNotTrack || navigator.doNotTrack) {
            if (window.doNotTrack === '1' || navigator.doNotTrack === 'yes' || navigator.doNotTrack === '1') {
                return 'Yes';
            } else {
                return 'No';
            }
        } else {
            return 'No';
        }
    }

    static getResolution(): string {
        const height: number = window.screen.height;
        const width: number = window.screen.width;

        return `${width}x${height}`;
    }

    static getViewportSize(): string {
        const height: number = window.screen.availHeight;
        const width: number = window.screen.availWidth;

        return `${width}x${height}`;
    }

    static getColorDepth(): string {
        const colorDepth: number = window.screen.colorDepth;

        return `${colorDepth}-bits`;
    }

    static getDevicePixelRatio(): string {
        return String(window.devicePixelRatio);
    }

    static getDocumentTitle(): string {
        const title: string = document.title;

        return encodeURIComponent(title);
    }

    static getDocumentPath(): string {
        const path: string = document.location.pathname;
        const params: string = document.location.search;
        const hash: string = document.location.hash;
        const fullPath = `${path}${params}${hash}`;

        return encodeURIComponent(fullPath);
    }

    static getDocumentReferrer(): string {
        let referrer: string = document.referrer;

        if (referrer) {
            referrer = deleteFromExcludedList(referrer);
        }

        return referrer;
    }

    static getDocumentLocation(): string {
        let location: string = document.location.href;

        location = deleteFromExcludedList(location);

        return encodeURIComponent(location);
    }

    static getDocumentEncoding(): string {
        return document.characterSet;
    }
}
