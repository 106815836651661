export default function linkEvents(): void {
    document.addEventListener('click', (e: Event) => {
        const target = e.target;

        if (target instanceof HTMLAnchorElement) {
            const title: string | null = target.title;
            const ariaLabel: string | null = target.getAttribute('aria-label');
            const linkText = title ? title : ariaLabel ? ariaLabel : target.innerText;
            const linkId = linkText.toLowerCase().split(' ').join('_');

            Analytics.sendEvent({
                category: 'Links',
                action: linkText,
                label: target.href,
                linkid: linkId,
            });
        }
    });
}
