export default function formEvents(): void {
    document.addEventListener('submit', (e: Event) => {
        const target = e.target;

        if (target instanceof HTMLFormElement) {
            Analytics.sendEvent({
                category: 'Forms',
                action: target.id ? target.id : target.name ? target.name : 'No form ID/Name',
                label: 'Submitted',
            });
        }
    });
}
