export default function externalLinkEvents(): void {
    const externalLinkTypes: string[] = ['_blank', 'external'];

    document.addEventListener('click', (e: Event) => {
        const target = e.target;

        if (target instanceof HTMLAnchorElement) {
            const elmTarget: string | null = target.getAttribute('target');
            const elmRel: string | null = target.getAttribute('rel');
            const title: string | null = target.title;
            const ariaLabel: string | null = target.getAttribute('aria-label');
            const linkText = title ? title : ariaLabel ? ariaLabel : target.innerText;

            if ((elmTarget && externalLinkTypes.includes(elmTarget)) || (elmRel && externalLinkTypes.includes(elmRel))) {
                Analytics.sendEvent({
                    category: 'External Links',
                    action: linkText,
                    label: target.href,
                });
            }
        }
    });
}
