import getClientId from './get-client-id';
import generateClientId from './generate-client-id';

export default function setGoogleAnalyticsClientIdParam(): Promise<string> {
    return getClientId().then((result: string) => {
        if (result) {
            return result;
        }

        return generateClientId();
    });
}
