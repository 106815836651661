import { TypeObject } from './typings';

import 'promise-polyfill/src/polyfill';

const promiseFinally = require('promise.prototype.finally');
promiseFinally.shim();

// Fetch
import 'whatwg-fetch';

// URLSearchParams
import 'url-search-params-polyfill';

// URL
import 'url-polyfill';

// navigator.sendbeacon
import 'navigator.sendbeacon';

// FormData
import 'formdata-polyfill';

// Array.prototype.includes();
import 'polyfill-array-includes';

// .closest()
if (Element && !Element.prototype.closest) {
    Element.prototype.closest = function (s: any) {
        const matches = (document || this.ownerDocument).querySelectorAll(s);
        let i: number;
        let el: any = this;

        do {
            i = matches.length;
            while (--i >= 0 && matches.item(i) !== el) {}
        } while (i < 0 && (el = el.parentElement));

        return el;
    };
}

// Object.entries() - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
if (!Object.entries) {
    Object.entries = function (obj: TypeObject) {
        const ownProps: string[] = Object.keys(obj);
        let i = ownProps.length;
        const resArray = new Array(i); // preallocate the Array

        while (i--) {
            resArray[i] = [ownProps[i], obj[ownProps[i]]];
        }

        return resArray;
    };
}

// .includes() - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/includes
if (!String.prototype.includes) {
    String.prototype.includes = function (search: string, start: number) {
        if (start === undefined) {
            start = 0;
        }

        return this.indexOf(search, start) !== -1;
    };
}
