import Debug from './debug';

export default class FileLoader {
    static css(url: string) {
        return new Promise((resolve: any, reject: any) => {
            this.load('link', url, resolve, reject);
        });
    }

    static js(url: string) {
        return new Promise((resolve: any, reject: any) => {
            this.load('script', url, resolve, reject);
        });
    }

    static load(tag: string, url: string, resolve: any, reject: any) {
        let element;

        switch (tag) {
            case 'script':
                element = document.createElement(tag) as HTMLScriptElement;
                element.async = true;
                element.defer = true;
                element.src = url;
                break;
            case 'link':
                element = document.createElement(tag) as HTMLLinkElement;
                element.rel = 'stylesheet';
                element.href = url;
                break;
            default:
                Debug.error(true, 'Unsupported tag.');
        }

        if (element) {
            document.head.appendChild(element);

            element.addEventListener('load', () => {
                resolve(true);
            });

            element.addEventListener('error', () => {
                reject(false);
            });
        }
    }
}
