export default function imageEvents(): void {
    const pagePath: string = window.location.pathname;
    const images: NodeListOf<HTMLImageElement> = document.querySelectorAll('img');

    window.addEventListener('DOMContentLoaded', () => {
        // Workaround as IE11 doesn't support .forEach on object, meh.
        [].forEach.call(images, (elm: HTMLImageElement) => {
            elm.addEventListener('error', () => {
                return Analytics.sendEvent({
                    category: 'Image',
                    action: 'Load Error',
                    label: `${elm.src} - ${pagePath}`,
                    non_interactive_event: true,
                });
            });

            elm.addEventListener('click', () => {
                return Analytics.sendEvent({
                    category: 'Image',
                    action: 'Click',
                    label: elm.src,
                });
            });
        });
    });
}
