export default function detectAdblock(): Promise<boolean> {
    const TEST_URL = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

    return fetch(TEST_URL, {
        method: 'HEAD',
        mode: 'no-cors',
    })
        .then((response: Response) => {
            return false;
        })
        .catch((response: Response) => {
            return true;
        });
}
