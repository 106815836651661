export default function emailLinkEvents(): void {
    const emailLinks: NodeListOf<HTMLLinkElement> = document.querySelectorAll('a[href^="mailto:"]');

    document.addEventListener('click', (e: Event) => {
        const target = e.target;

        if (target instanceof HTMLAnchorElement) {
            if (target.href.includes('mailto:')) {
                const title: string | null = target.title;
                const ariaLabel: string | null = target.getAttribute('aria-label');
                const linkText = title ? title : ariaLabel ? ariaLabel : target.innerText;
                const linkId = linkText.toLowerCase().split(' ').join('_');

                Analytics.sendEvent({
                    category: 'Email Links',
                    action: linkText,
                    label: target.href.replace('mailto:', ''),
                    linkid: linkId,
                });
            }
        }
    });
}
