import screenOrientationDimension from './screen-orientation';
import fullscreenDimension from './fullscreen';
import referrerDimension from './referrer';
import doNotTrackDimension from './do-not-track';
import devicePixelRatio from './device-pixel-ratio';
import detectPreferedColorScheme from './prefered-color-scheme';

export default function setDimensions(): void {
    screenOrientationDimension();
    fullscreenDimension();
    referrerDimension();
    doNotTrackDimension();
    devicePixelRatio();
    detectPreferedColorScheme();
}
