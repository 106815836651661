import screenOrientationChangeEvents from './orientation-change-events';
import urlParametersEvents from './url-params-events';

// import mouseEvents from './mouse-events';

import linkEvents from './link-events';
import emailLinkEvents from './email-link-events';
import externalLinkEvents from './external-link-events';
import imageEvents from './image-events';

import formEvents from './form-events';
import buttonEvents from './button-events';
import inputEvents from './input-events';
import quickSearchEvents from './quick-search-events';

export default function attachEvents(): void {
    screenOrientationChangeEvents();
    urlParametersEvents();
    // mouseEvents();
    linkEvents();
    emailLinkEvents();
    externalLinkEvents();
    imageEvents();
    formEvents();
    buttonEvents();
    inputEvents();
    quickSearchEvents();
}
