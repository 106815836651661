export default class Debug {
    public static readonly DEBUG_PREFIX: string = 'Analytics:';

    constructor() {}

    public static error(debugEnabled: boolean, ...args: any[]) {
        args = [this.DEBUG_PREFIX, ...args];

        if (debugEnabled) {
            console.error.apply(console, Array.prototype.slice.call(args));
        }
    }

    public static warn(debugEnabled: boolean, ...args: any[]) {
        args = [this.DEBUG_PREFIX, ...args];

        if (debugEnabled) {
            console.warn.apply(console, Array.prototype.slice.call(args));
        }
    }

    public static log(debugEnabled: boolean, ...args: any[]) {
        args = [this.DEBUG_PREFIX, ...args];

        if (debugEnabled) {
            console.log.apply(console, Array.prototype.slice.call(args));
        }
    }
}
