export default function generateClientId(): string {
    const timeStamp: number = Math.round(+new Date() / 1000.0);

    let randomNumber: number;

    try {
        const uu32: Uint32Array = new Uint32Array(1);
        randomNumber = crypto.getRandomValues(uu32)[0];
    } catch (e) {
        randomNumber = Math.round(Math.random() * 2147483647);
    }

    return [randomNumber, timeStamp].join('.');
}
