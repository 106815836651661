import { DefaultConfig, PropertyParameters, EventParameters, EventPayloadParameters, EventArray, TypeObject, SetParameterType, SetParameterValue, DefaultPayload } from './typings';

import FileLoader from './helpers/file-loader';
import Debug from './helpers/debug';
import detectAdBlock from './helpers/detect-adblock';
import setGoogleAnalyticsClientId from './helpers/set-client-id';
import setGoogleAnalyticsClientIdParam from './helpers/set-client-id-as-param';
import VisitorData from './helpers/visitor-data';
import unixTimestamp from './helpers/unix-timestamp';
import proxyRequest from './helpers/proxy-request';

import setDimensions from './dimensions';
import attachEvents from './events';

export default class Analytics {
    private readonly INTERFACE_TYPE: TypeObject = {
        PDA: 'PDA',
        TAB: 'TAB',
        Desktop: 'Desktop',
        Chathost: 'Chathost',
        ChathostPDA: 'Chathost PDA',
    };
    private readonly ACCOUNT_TYPE: TypeObject = {
        0: 'Guest', // CC_USERTYPE_GUEST
        1: 'Member', // CC_USERTYPE_MEMBER
        2: 'Chathost', // CC_USERTYPE_CHATHOST
        4: 'Studio', // CC_USERTYPE_STUDIO
        8: 'Referrer', // CC_USERTYPE_REFERRER
    };
    private readonly ACCOUNT_MODIFIER: TypeObject = {
        10: 'Registered', // 10 - registered
        30: 'Pre-Authorized', // 30 - preauthorized
        50: 'Free credits', // 50 - has free credits
        70: 'Spender', // 70 - spender
    };

    private CONFIG: DefaultConfig = {
        transport_type: 'beacon',
        link_attribution: true,
        custom_map: {
            dimension1: 'account_modifier',
            dimension2: 'account_type',
            dimension3: 'interface',
            dimension4: 'account_group',
            dimension5: 'orientation',
            dimension6: 'fullscreen',
            dimension7: 'adblock_status',
            dimension8: 'referrer',
            dimension9: 'account_id',
            dimension10: 'video_session_id',
            dimension11: 'do_not_track',
            dimension12: 'pixel_ratio',
            dimension13: 'session_id',
            dimension14: 'login_id',
            dimension15: 'frontend_version',
            dimension16: 'prefers_color_scheme',
        },
    };
    private PAYLOAD: DefaultPayload = {
        version: 1,
        data_source: 'web',
        document_location: VisitorData.getDocumentLocation(),
        document_path: this.CONFIG.page_path || VisitorData.getDocumentPath(),
        document_title: VisitorData.getDocumentTitle(),
        document_encoding: VisitorData.getDocumentEncoding(),
        document_referrer: VisitorData.getDocumentReferrer(),
        screen_resolution: VisitorData.getResolution(),
        viewport_size: VisitorData.getViewportSize(),
        color_depth: VisitorData.getColorDepth(),
        pixel_ratio: VisitorData.getDevicePixelRatio(),
        java_enabled: 0,
        user_agent: VisitorData.getBrowser(),
        user_language: VisitorData.getBrowserLanguage(),
    };
    private PROPERTIES: PropertyParameters;
    private DEBUG: boolean = false;
    private DISABLED: boolean = false;
    private DIMENSIONS: any = {};
    private IS_ADBLOCK: boolean = false;
    private IS_ANALYTICS_BLOCKED: boolean = false;
    private EVENT_ORDER: number = 1;

    constructor(params: PropertyParameters) {
        this.PROPERTIES = params;
        this.PAYLOAD.property_id = this.PROPERTIES.primaryId;

        setGoogleAnalyticsClientIdParam().then((result: string) => {
            this.PAYLOAD.client_id = result;
        });

        detectAdBlock()
            .then((response: boolean) => {
                this.IS_ADBLOCK = response;
                this.IS_ANALYTICS_BLOCKED = response;

                this.set('adblock_status', this.IS_ADBLOCK ? 'Enabled' : 'Disabled');
            })
            .then(() => {
                // Set calculated dimensions before Google Analytics .init()
                setDimensions();

                this.setDisabled(this.PROPERTIES.primaryId, this.DISABLED);

                if (this.PROPERTIES.secondaryId) {
                    this.setDisabled(this.PROPERTIES.secondaryId, this.DISABLED);
                }
            })
            .then(() => {
                this.sendProxyPageview();
                this.sendProxyLoadTime();

                this.init();
            });
    }

    public set(type: SetParameterType, value: SetParameterValue): void {
        switch (type) {
            case 'user_id':
                this.CONFIG.user_id = value;
                this.PAYLOAD.user_id = value;
                this.DIMENSIONS.account_id = value;
                break;

            case 'currency':
                this.CONFIG.currency = value;
                this.PAYLOAD.currency = value;
                break;

            case 'country':
                this.CONFIG.country = value;
                this.PAYLOAD.country = value;
                break;

            case 'page_path':
                this.CONFIG.page_path = value;
                this.PAYLOAD.document_path = value;
                break;

            case 'account_type':
                this.DIMENSIONS.account_type = this.ACCOUNT_TYPE[value];
                break;

            case 'interface':
                this.DIMENSIONS.interface = this.INTERFACE_TYPE[value];
                break;

            case 'account_modifier':
                this.DIMENSIONS.account_modifier = this.ACCOUNT_MODIFIER[value];
                break;

            case 'debug':
                this.DEBUG = !!value;
                break;

            case 'disabled':
                this.DISABLED = !!value;
                break;

            default:
                this.DIMENSIONS[type] = value;
                break;
        }

        Debug.log(this.DEBUG, type, value);
    }

    public init(): void {
        if (!this.DISABLED) {
            FileLoader.js(`https://www.googletagmanager.com/gtag/js?id=${this.PROPERTIES.primaryId}`)
                .then(() => {
                    this.setProperties();
                })
                .catch(() => {
                    this.IS_ANALYTICS_BLOCKED = true;

                    Debug.error(true, 'Google Analytics was blocked by AdBlocker');
                })
                .finally(() => {
                    // Set Google Analytics Client ID
                    setGoogleAnalyticsClientId();

                    // Attach event handlers for events after .init()
                    attachEvents();

                    this.sendLoadTime();
                });
        }
    }

    // Send sigle event
    public sendEvent(params: EventParameters): void {
        const { category, action, label, value, non_interactive_event, linkid }: EventParameters = params;
        const payload: EventPayloadParameters = {
            event_category: category,
            ...this.DIMENSIONS,
        };

        if (label) {
            payload.event_label = label;
        }

        if (Number(value) && Math.round(Number(value)) > 0) {
            payload.value = Math.round(Number(value));
        }

        payload.non_interactive = !!non_interactive_event;

        Debug.log(this.DEBUG, 'event', action, payload);

        this.sendProxyEvent(action, payload, linkid);

        if (!this.IS_ANALYTICS_BLOCKED) {
            this.canSendData()
                .then(() => {
                    gtag('event', action, payload);
                })
                .catch((err: string) => {
                    this.IS_ANALYTICS_BLOCKED = true;
                    this.IS_ADBLOCK = true;

                    Debug.warn(true, err);
                });
        }
    }

    private sendProxyEvent(action: string, event: EventPayloadParameters, linkid: string = ''): void {
        const eventPayload: EventPayloadParameters = { ...event };

        eventPayload.event_category = encodeURIComponent(eventPayload.event_category);

        if (eventPayload.event_label) {
            eventPayload.event_label = encodeURIComponent(eventPayload.event_label);
        }

        const data = {
            timestamp: unixTimestamp(),
            event_order: this.EVENT_ORDER++,
            type: 'event',
            event_action: encodeURIComponent(action),
            ...eventPayload,
            ...this.PAYLOAD,
            ...this.DIMENSIONS,
        };

        if (linkid) {
            data.linkid = linkid;
        }

        // Send data through proxy
        proxyRequest(data);

        // Send data for secondary ID as well
        if (this.PROPERTIES.secondaryId) {
            data.property_id = this.PROPERTIES.secondaryId;
            proxyRequest(data);
        }
    }

    // Send bulk events
    public sendEvents(events: Array<EventParameters>): void {
        events.forEach((event: EventParameters) => {
            return this.sendEvent(event);
        });
    }

    // Check that provided data is array and normalize provided data
    public createEventsFromArray(events: Array<EventArray>): Array<EventParameters> | void {
        const normalizedEvents: Array<EventParameters> = [];

        if (Array.isArray(events)) {
            events.forEach((event: EventParameters | EventArray) => {
                if (Array.isArray(event)) {
                    const [category, action, label, value, non_interactive_event]: EventArray = event;

                    const normalizedEvent: EventParameters = {
                        category: category,
                        action: action,
                        label: label,
                        value: value,
                        non_interactive_event: non_interactive_event ? non_interactive_event : true,
                    };

                    normalizedEvents.push(normalizedEvent);
                } else {
                    normalizedEvents.push(event);
                }
            });

            return normalizedEvents;
        }

        return Debug.error(true, 'Provided data is not an ARRAY.');
    }

    // Check if we can send data to Analytics
    private canSendData() {
        return new Promise((resolve: any, reject: any) => {
            const TIME_START = Date.now();
            const TIMEOUT = 10000;

            (function canSend() {
                if (Date.now() - TIME_START > TIMEOUT) {
                    reject(`Google Analytics are not ready to receive data.`);
                    return;
                }

                if ((<any>window).ga && typeof (<any>window).ga === 'function') {
                    resolve(true);
                    return;
                } else {
                    setTimeout(canSend, 500);
                }
            })();
        });
    }

    private setProperties() {
        const { primaryId, secondaryId, conversionId }: PropertyParameters = this.PROPERTIES;

        gtag('js', new Date());
        gtag('config', `${primaryId}`, this.CONFIG);

        if (secondaryId) {
            gtag('config', `${secondaryId}`, this.CONFIG);
        }

        if (conversionId) {
            gtag('config', `AW-${conversionId}`, this.CONFIG);
        }
    }

    private sendProxyPageview(): void {
        // Pageview for primary property
        const data = {
            timestamp: unixTimestamp(),
            event_order: this.EVENT_ORDER++,
            type: 'pageview',
            ...this.PAYLOAD,
            ...this.DIMENSIONS,
        };

        Debug.log(this.DEBUG, data);

        proxyRequest(data);

        // Pageview for secondary property
        if (this.PROPERTIES.secondaryId) {
            data.property_id = this.PROPERTIES.secondaryId;
            proxyRequest(data);
        }
    }

    private sendLoadTime(): void {
        const timeSincePageLoad = this.calculateLoadTime();

        if (timeSincePageLoad) {
            gtag('event', 'timing_complete', {
                name: 'load',
                value: timeSincePageLoad,
                event_category: 'JS Dependencies',
                ...this.DIMENSIONS,
            });
        }
    }

    private sendProxyLoadTime(): void {
        const timeSincePageLoad = this.calculateLoadTime();

        if (timeSincePageLoad) {
            // Page timing for primary property
            const data = {
                timestamp: unixTimestamp(),
                event_order: this.EVENT_ORDER++,
                type: 'timing',
                user_timing_label: encodeURIComponent('JS Dependencies'),
                user_timing_variable: 'load',
                user_timing_time: timeSincePageLoad,
                ...this.PAYLOAD,
                ...this.DIMENSIONS,
            };

            proxyRequest(data);

            Debug.log(this.DEBUG, data);

            // Page timing for secondary property
            if (this.PROPERTIES.secondaryId) {
                data.property_id = this.PROPERTIES.secondaryId;
                proxyRequest(data);
            }
        }
    }

    // Calculate JS load time
    private calculateLoadTime(): number | null {
        if (window.performance) {
            return Math.round(window.performance.now());
        }

        return null;
    }

    // Set disable for Google Analytics
    private setDisabled(propertyId: string, value: boolean): void {
        (<any>window)[`ga-disable-${propertyId}`] = value;
    }
}
