import { TypeObject } from '../typings';
import getUrlParams from '../helpers/get-url-params';

export default function referrerDimension(): void {
    const params: TypeObject = getUrlParams();
    const referrers: TypeObject = {
        1408396: 'Instagram',
        1408397: 'Tumblr',
        1408398: 'Ads',
        1408399: 'Brand Twitter',
        1408442: 'Facebook',
        1406091: 'adWords/Bing',
        1408239: 'Blogs',
        1406449: 'Chathost Twitter',
    };

    if (params.hasOwnProperty('Ref') || params.hasOwnProperty('ref')) {
        const value: string = referrers[params.Ref] || referrers[params.ref] || params.ref || params.Ref;

        Analytics.set('referrer', value);
    }
}
