import { TypeObject } from '../typings';

export default function getUrlParams(): TypeObject {
    const paramObject: TypeObject = {};
    const excludedParams: string[] = ['sidk', 'q', 'buttonSearch', 'csrf', 'videoSessionID', 'videoSessionGUID', 'SelfFrameType', 'reloadLeftFrame', 'selfFrameType'];

    const queryString: string = window.location.search.slice(1);

    if (queryString) {
        const params: URLSearchParams = new URLSearchParams(queryString);

        excludedParams.forEach((item: string) => {
            params.delete(item);
        });

        params.forEach((value: string, key: string) => {
            if (value) {
                value = value.replace('s%5B', '').replace('%5D', '').replace('s[', '').replace(']', '');

                paramObject[key] = decodeURIComponent(value);
            }
        });
    }

    return paramObject;
}
